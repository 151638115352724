import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { CallSource, emptyCallSource } from '../../interfaces/callSource';
import {
  CampaignAttributes,
  DisputeInfo,
  emptyCampaignAttributes
} from '../../interfaces/campaign';
import { DisputeStatus, Hop, HopDispute } from '../../interfaces/hop';
import { StirShakenInfo } from '../../interfaces/stirShaken';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import {
  ValidationRules,
  isFormValid,
  regexRule,
  requiredRule,
  useValidator
} from '../../lib/hooks';
import { validatePhoneNumber } from '../../lib/phoneNumber';
import { validEmailRegex, validateIpAddress } from '../../lib/regex';
import {
  gerenerateStirShakenRequestObj,
  getApiFormattedDate,
  getClientFormattedDate,
  isNotReservedIPv4
} from '../../lib/utilities';
import { addCommentObject } from '../../redux/comment/thunks';
import {
  getProviderIdForEmail,
  getProviderInSequenceStatusApiCall
} from '../../redux/hop/apiCalls';
import { getProviderIsNonResponsive } from '../../redux/provider/thunks';
import { stateMappings } from '../../redux/stateMappings';
import NewProviderPopup, { GetZeroBounceError } from '../NewProviderPopup';
import ProvidersSelect, { ProviderItem } from '../ProvidersSelect';
import AddHopComment from '../comments/AddHopComment';
import InputError from '../inputError';
import InputFormGroup from '../inputFormGroup';
import StirShakenModal from '../modals/StirShakenModal';
import CallSourceCustomerForm, { callSourceTypes } from './CallSourceCustomerForm';

export const customerValidator: ValidationRules = {
  businessName: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  contactEmail: [
    {
      rule: regexRule(validEmailRegex),
      message: 'An email address is required.'
    }
  ],
  country: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  actionTaken: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  ipAddress: [
    {
      rule: validateIpAddress,
      message: "That's not a valid IP address."
    },
    { rule: isNotReservedIPv4, message: "That's a reserved IP Address." }
  ]
};
interface IProps {
  hop: Hop;
  traceback: Traceback;
  user: PersonalInfo;
  updateHopObject: Function;
  providersData: ProviderItem[];
  onChange: (isCallSourcePopulated: boolean) => void;
  campaignAttribute: CampaignAttributes;
  isNrProvider: boolean;
  getProviderIsNonResponsive: Function;
}

const CallSourceRow: React.FC<IProps> = ({
  hop,
  traceback,
  user,
  updateHopObject,
  onChange,
  providersData,
  campaignAttribute,
  isNrProvider,
  getProviderIsNonResponsive
}) => {
  const [currentCallSource, setCurrentCallSource] = useState('');

  const [selectedProvider, setSelectedProvider] = useState(0);
  const selectedProviderData = providersData.find((v) => v.id === selectedProvider);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [extraContactEmail, setExtraContactEmail] = useState('');
  const [extraCommentRmd, setExtraCommentRmd] = useState('');
  const [extraContactEmailError, setExtraContactEmailError] = useState('');
  const [extraCommentRmdError, setExtraCommentRmdError] = useState('');

  const [callOriginatorAndCallingParty, setCallOriginatorAndCallingParty] = useState(false);
  const [callSource, setCallSource] = useState<CallSource>(emptyCallSource());
  const [validateCallSourceErrors, clearCallSourceErrors] = useValidator(customerValidator);
  const [disputeInfo, setDisputeInfo] = useState<DisputeInfo>({
    disputeVisible: false,
    attributes: emptyCampaignAttributes(),
    comment: '',
    consentGiven: false,
    attachments: []
  });

  const [newTracebackTime, setNewTracebackTime] = useState('');
  const [forwardedCall, setForwardedCall] = useState('');

  const [stirShakenInfo, setStirShakenInfo] = useState<StirShakenInfo>({
    toggleStirShaken: false,
    stirShakenToken: '',
    signedStirShaken: null,
    attestationRadio: '',
    destinationNumber: '',
    callingNumber: '',
    timeStamp: '',
    originationIdentifier: '',
    isCallSigner: false
  });

  const [findRecordModal, setFindRecordModal] = useState(false);

  const toggleFindRecordModal = () => {
    setFindRecordModal((v) => !v);
  };

  // modals controls
  const [isSubmit, setIsSubmit] = useState(false);
  const [providerAlreadyInSequenceModal, setProviderAlreadyInSequenceModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [immediateDownstreamModal, setImmediateDownstreamModal] = useState(false);
  const [customerEmailModal, setCustomerEmailModal] = useState(false);
  const [customerEmailProvider, setCustomerEmailProvider] = useState<ProviderItem>();

  const toggleImmediateDownstreamModal = () => {
    setImmediateDownstreamModal((v) => !v);
  };

  useEffect(() => {
    onChange(currentCallSource === 'isUpstream' ? !!selectedProvider : !!currentCallSource);
  }, [currentCallSource, selectedProvider]);

  useEffect(() => {
    if (selectedProvider) getProviderIsNonResponsive(selectedProvider);
  }, [selectedProvider]);

  useEffect(() => {
    if (!selectedProviderData?.badEmails) return;

    if (!extraContactEmail)
      setExtraContactEmailError(
        `Please submit an alternative email for the selected voice service provider.`
      );
    else if (!validEmailRegex.test(extraContactEmail))
      setExtraContactEmailError('Please provide a valid email address');
    else setExtraContactEmailError('');
  }, [selectedProviderData?.badEmails, extraContactEmail]);

  useEffect(() => {
    if (
      traceback.isInternational ||
      !selectedProviderData?.noRmd ||
      user.providerCountry !== 'United States'
    )
      return;
    if (!extraCommentRmd)
      setExtraCommentRmdError(
        `Please note that the selected provider is not registered in the FCC's Robocall Mitigation Database. If you feel this is an error please add the RMD number or name of entity here.`
      );
    else if (extraCommentRmd.length <= 5)
      setExtraCommentRmdError('Comment must have at least 5 characters');
    else setExtraCommentRmdError('');
  }, [
    selectedProviderData?.noRmd,
    extraCommentRmd,
    user.providerCountry,
    traceback.isInternational
  ]);

  useEffect(() => {
    if (
      callSource.currentCause === 'isRecordUn' &&
      hop.comments.filter((v) => v.directedTo === 'downstream').length === 0 &&
      !!hop.downstreamHopId
    ) {
      setFindRecordModal(true);
    }
  }, [callSource.currentCause]);

  const handleCallSourceSettingChange = (e: any) => {
    setCurrentCallSource(e.target.value);

    setSelectedProvider(0);
    setExtraContactEmail('');

    setCallOriginatorAndCallingParty(false);
    setCallSource(emptyCallSource());
  };

  const handleProviderChange = (e: any) => {
    if (!e) {
      setSelectedProvider(0);
    } else {
      setSelectedProvider(e.value);
      setCurrentCallSource(callSourceTypes.isUpstream);
    }
  };

  const popoverToggle = () => {
    setPopoverOpen((v) => !v);
    setCurrentCallSource(callSourceTypes.isUpstream);
  };

  const handleCallSourceInputChange = (e: any) => {
    if (e !== null && e.currentTarget) {
      const { name, value } = e.currentTarget;
      switch (name) {
        case 'explanation': {
          setCallSource((v) => ({ ...v, explanation: value }));
          setCurrentCallSource(callSourceTypes.isCallRecordNotProvided);
          break;
        }
        case 'extraContactEmail':
          setExtraContactEmail(value);
          setCurrentCallSource(callSourceTypes.isUpstream);
          break;
        case 'extraCommentRmd':
          setExtraCommentRmd(value);
          setCurrentCallSource(callSourceTypes.isUpstream);
          break;
        default:
          break;
      }
    }
  };

  const canSubmit = (): boolean => {
    switch (currentCallSource) {
      case callSourceTypes.isUpstream:
        return (
          providersData.length > 0 &&
          (!selectedProviderData?.badEmails || !extraContactEmailError) &&
          (traceback.isInternational ||
            !selectedProviderData?.noRmd ||
            user.providerCountry !== 'United States' ||
            !extraCommentRmdError) &&
          !!selectedProvider
        );
      case callSourceTypes.isCustomer:
        return (
          validateIpAddress(callSource.ipAddress) &&
          isNotReservedIPv4(callSource.ipAddress) &&
          callSource.ipAddress !== '' &&
          isFormValid(customerValidator, callSource) &&
          (disputeInfo.consentGiven
            ? !!disputeInfo.comment ||
              (disputeInfo.attachments && disputeInfo.attachments.length > 0)
            : true)
        );
      case callSourceTypes.isCallRecordNotProvided:
        return !!(callSource.explanation && callSource.currentCause);
    }
    return false;
  };

  const handleCauseChange = (e: any) => {
    const value = e.currentTarget.value;
    setCallSource((v) => ({ ...v, currentCause: value }));
    setCurrentCallSource(callSourceTypes.isCallRecordNotProvided);
  };

  const toggleCustomerEmailModal = () => {
    setCustomerEmailModal((v) => !v);
  };
  useEffect(() => {
    setCustomerEmailProvider(undefined);
  }, [callSource.contactEmail]);

  const submitConfirmModalToggle = async (customerEmailConfirmation: boolean = false) => {
    if (forwardedCall && !validatePhoneNumber(forwardedCall)) {
      return;
    }
    switch (currentCallSource) {
      case callSourceTypes.isCustomer:
        if (!customerEmailConfirmation) {
          if (!customerEmailProvider) {
            try {
              const rsp = await getProviderIdForEmail({
                email: callSource.contactEmail,
                hopId: hop.hopId,
                tfhopId: 0
              });
              if (rsp.data) {
                const provider = providersData.find((v) => v.id === rsp.data);
                setCustomerEmailProvider(provider);
                setCustomerEmailModal(!!provider);
                if (!!provider) {
                  return;
                }
              }
            } catch (error: any) {
              console.log('Error:', error);
            }
          } else {
            setCustomerEmailModal(true);
            return;
          }
        }
        if (
          callSource.addressLine1 === '' ||
          callSource.city === '' ||
          (callSource.region === '' && callSource.country !== 'Unknown') ||
          callSource.postalCode === '' ||
          callSource.ipAddress === '0.0.0.0'
        ) {
          setAddressModal(true);
        } else {
          traceback.isInternational ? submitCallSourceToggle() : toggleStirShakenModal();
        }
        break;
      case callSourceTypes.isUpstream:
        try {
          if (selectedProviderData?.badEmails) {
            const zeroBounceError = await GetZeroBounceError(extraContactEmail);
            if (zeroBounceError) {
              setExtraContactEmailError(
                `The email has an issue - ${zeroBounceError}. Please provide a different email`
              );
              return;
            }
          }
          const providerAlreadyInSequence = await getProviderInSequenceStatusApiCall(
            hop.tracebackId,
            selectedProvider
          );
          if (
            !!hop.downstreamProvider &&
            selectedProvider === hop.downstreamProvider.providerId &&
            hop.comments.filter((v) => v.directedTo === 'downstream').length === 0
          ) {
            setImmediateDownstreamModal(true);
            return;
          }
          if (providerAlreadyInSequence.data.data) {
            providerAlreadtyInSequenceToggle();
            return;
          }
          traceback.isInternational ? submitCallSourceToggle() : toggleStirShakenModal();
        } catch (error: any) {
          console.log('Error:', error);
        }
        break;
      case callSourceTypes.isCallRecordNotProvided:
        if (
          callSource.currentCause === 'isRecordUn' &&
          hop.comments.filter((v) => v.directedTo === 'downstream').length === 0 &&
          !!hop.downstreamHopId
        ) {
          setFindRecordModal(true);
          return;
        }
        setIsSubmit(true);
        break;
      default:
        break;
    }
  };

  const toggleStirShakenModal = () => {
    setStirShakenInfo((v) => ({
      ...v,
      toggleStirShaken: !v.toggleStirShaken,
      signedStirShaken: null,
      attestationRadio: ''
    }));
  };

  const submitCallSourceToggle = () => {
    setIsSubmit((v) => !v);
  };

  const providerAlreadtyInSequenceToggle = () => {
    setProviderAlreadyInSequenceModal((v) => !v);
  };
  const addressModalToggle = () => {
    setAddressModal((v) => !v);
  };

  const submitComments = () => {
    const isExtraRMD =
      !traceback.isInternational &&
      selectedProviderData?.noRmd &&
      extraCommentRmd &&
      user.providerCountry === 'United States';
    const isBadEmail = selectedProviderData?.badEmails && extraContactEmail;
    if (isExtraRMD || isBadEmail) {
      addCommentObject(
        {
          commentType: 'hop',
          contentText:
            isExtraRMD && isBadEmail
              ? `Alternate contact for provider (${selectedProviderData.name}) is: ${extraContactEmail} and RMD info is: ${extraCommentRmd}`
              : !isExtraRMD && isBadEmail
                ? `An alternative email for the selected provider (${selectedProviderData.name}) is: ${extraContactEmail}`
                : `RMD info for provider (${selectedProviderData.name}) is: ${extraCommentRmd}`,
          relatedObjectId: hop.hopId,
          directedTo: 'upstream'
        },
        []
      ).then(() => {
        setExtraContactEmail('');
      });
    }
  };

  const submitCallSource = async () => {
    submitCallSourceToggle();

    let hopToSubmit: any = {};

    if (currentCallSource === callSourceTypes.isUpstream) {
      hopToSubmit = {
        hopId: hop.hopId,
        upstreamProviderId: selectedProvider,
        delegatedTo: user.email,
        forwardedCall: forwardedCall,
        stirShakenRequest: gerenerateStirShakenRequestObj(stirShakenInfo),
        contactEmail: selectedProvider === customerEmailProvider?.id ? callSource.contactEmail : ''
      };
    } else if (currentCallSource === callSourceTypes.isCustomer) {
      hopToSubmit = {
        hopId: hop.hopId,
        address: callSource.addressLine1,
        city: callSource.city,
        customer: callSource.businessName,
        contactEmail: callSource.contactEmail,
        contactPhone: callSource.contactPhone,
        contactName: callSource.contactName,
        country: callSource.country,
        state: callSource.region,
        zipCode: callSource.postalCode,
        explanation: callSource.stepsTaken,
        isCallOrig: callOriginatorAndCallingParty,
        delegatedTo: user.email,
        forwardedCall: forwardedCall,
        ipAddress: callSource.ipAddress,
        customerType: callSource.customerType,
        averageTraffic: callSource.averageTraffic,
        actionTaken: callSource.actionTaken,
        refuseReason: callSource.reasonRejection,
        stirShakenRequest: gerenerateStirShakenRequestObj(stirShakenInfo)
      };
      if (disputeInfo.disputeVisible) {
        const commentId = await addCommentObject(
          {
            commentType: 'hop',
            contentText: 'Dispute: ' + disputeInfo.comment,
            relatedObjectId: hop.hopId,
            directedTo: 'admin'
          },
          disputeInfo.attachments
        );
        if (commentId && Number(commentId)) {
          const dispute: HopDispute = {
            hopId: hop.hopId,
            status: DisputeStatus.OpenDispute,
            commentId,
            attributes: disputeInfo.attributes,
            consentGiven: disputeInfo.consentGiven
          };
          hopToSubmit.dispute = dispute;
        }
      }
    } else if (currentCallSource === callSourceTypes.isCallRecordNotProvided) {
      hopToSubmit = {
        hopId: hop.hopId,
        nfnr: callSource.currentCause,
        nfnrReason: callSource.explanation,
        delegatedTo: user.email,
        forwardedCall: forwardedCall
      };
    }

    if (newTracebackTime) {
      hopToSubmit.newTracebackTime = getApiFormattedDate(newTracebackTime);
      if (hopToSubmit.newTracebackTime === '') {
        submitConfirmModalToggle();
      }
    }

    await updateHopObject(hopToSubmit);
    if (currentCallSource === callSourceTypes.isUpstream) {
      submitComments();
    }
    localStorage.setItem('currentCallSource', currentCallSource);
  };

  const submitAlreadyExistingPreviousProviderHop = () => {
    traceback.isInternational ? submitCallSourceToggle() : toggleStirShakenModal();
    providerAlreadtyInSequenceToggle();
  };

  const handleReasonReject = (e: any) => {
    setCallSource((v) => ({ ...v, reasonRejection: e }));
  };

  const continueToModal = () => {
    setAddressModal(false);
    traceback.isInternational ? submitCallSourceToggle() : toggleStirShakenModal();
  };
  return (
    <Fragment>
      <h5 className="p-0 traceback-sub-title mb-3">Who provided you with this call?</h5>
      <Form className="highlighted-background mb-4">
        <Label className="telecom-label p-0" style={{ fontSize: '20px' }}>
          Call Source
        </Label>
        <Label className="telecom-label p-0">
          I received this call from
          <i className="fa fa-asterisk asterisk" />
        </Label>
        <div className="d-flex flex-row flex-wrap mt-2 mb-3">
          <div>
            <input
              type="radio"
              value={callSourceTypes.isUpstream}
              className="me-2"
              onChange={(e) => {
                handleCallSourceSettingChange(e);
              }}
              checked={currentCallSource === callSourceTypes.isUpstream}
            />
            Upstream Voice Service Provider
          </div>
          <div
            className={`upstream-select
              ${
                currentCallSource && currentCallSource !== callSourceTypes.isUpstream
                  ? 'is-disable'
                  : ''
              }`}
          >
            <ProvidersSelect
              onChange={(e) => handleProviderChange(e)}
              isSearchable
              isClearable
              className={'customselect-large'}
              option={selectedProvider}
              removeId={hop.hopProvider?.providerId}
              placeholder="All Providers"
            />
          </div>
          {user.roleType !== userTypes.Admin && (
            <div
              className={`
                ${
                  currentCallSource && currentCallSource !== callSourceTypes.isUpstream
                    ? 'ms-4 is-disable'
                    : 'ms-4'
                }`}
            >
              <p className="mb-0">Is your upstream provider not listed in the dropdown?</p>
              <span className="span-cell blue" id="Popover1" style={{ cursor: 'pointer' }}>
                + Add New Provider
              </span>
              <NewProviderPopup
                isOpen={popoverOpen}
                toggle={popoverToggle}
                handleProviderChange={handleProviderChange}
              />
            </div>
          )}
        </div>
        {isNrProvider && selectedProviderData && (
          <div className="d-flex flex-row mb-3">
            <Label className="telecom-label w-50">Alert for upstream provider:</Label>
            <span className="text-danger">
              The upstream provider selected, {selectedProviderData.name} , has been non responsive
              in prior traceback requests. US providers are required to respond to traceback
              requests. Please encourage your connecting partner who you accept traffic from, to
              comply with this and future traceback requests.{' '}
            </span>
          </div>
        )}
        {selectedProviderData?.badEmails && (
          <div className="d-flex flex-row">
            <div
              className={`
                ${currentCallSource === callSourceTypes.isUpstream ? '' : 'is-disable'}`}
            >
              <Label className="telecom-label">Alternative Contact Email</Label>
            </div>
            <div
              className={`upstream-comment ${currentCallSource === callSourceTypes.isUpstream ? '' : 'is-disable'}`}
            >
              <InputFormGroup
                isReadonly={false}
                inputName="extraContactEmail"
                inputId="extraContactEmail"
                inputClassName="input-provider"
                inputValue={extraContactEmail}
                inputOnChange={handleCallSourceInputChange}
                inputPlaceholder=""
                inputAutoComplete="off"
                errorMessage={extraContactEmailError}
              />
            </div>
          </div>
        )}

        {!traceback.isInternational &&
          selectedProviderData?.noRmd &&
          user.providerCountry === 'United States' && (
            <div className="d-flex flex-row">
              <div
                className={`${currentCallSource === callSourceTypes.isUpstream ? '' : 'is-disable'}`}
              >
                <Label className="telecom-label">Comment for No RMDs Provider</Label>
              </div>
              <div
                className={`upstream-comment ${currentCallSource === callSourceTypes.isUpstream ? '' : 'is-disable'}`}
              >
                <InputFormGroup
                  isReadonly={false}
                  inputName="extraCommentRmd"
                  inputId="extraCommentRmd"
                  inputClassName="input-provider"
                  inputValue={extraCommentRmd}
                  inputOnChange={handleCallSourceInputChange}
                  inputPlaceholder=""
                  inputAutoComplete="off"
                  errorMessage={extraCommentRmdError}
                />
              </div>
            </div>
          )}
        {hop && hop.hopSequence === 1 && hop.status === 1 && (
          <div className="d-flex justify-content-center mt-2">
            <div className="d-flex flex-column">
              <label className="telecom-label ms-2 me-2">Corrected traceback time:</label>
            </div>
            <div className="d-flex flex-column me-5">
              <InputFormGroup
                inputName="newTracebackTime"
                inputId="newTracebackTime"
                inputClassName="input-traceback"
                inputValue={newTracebackTime}
                inputPlaceholder="2020-03-07 19:55"
                inputOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewTracebackTime(e.target.value);
                }}
                inputAutoComplete="off"
                errorMessage={
                  newTracebackTime && !getApiFormattedDate(newTracebackTime)
                    ? 'Please provide a valid date.'
                    : ''
                }
              />
            </div>
          </div>
        )}
        {hop && (!hop.forwardedCall || hop.forwardedCall.trim() === '') && (
          <div className="d-flex justify-content-center mt-2">
            <div className="d-flex flex-column">
              <Label className="telecom-label ms-2">Forwarded Call:</Label>
            </div>
            <div className="d-flex flex-column me-1">
              <InputFormGroup
                inputName="forwardedCall"
                inputId="forwardedCall"
                inputClassName="input-traceback"
                inputValue={forwardedCall}
                inputPlaceholder="2025558387"
                inputOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setForwardedCall(e.target.value);
                }}
                inputAutoComplete="off"
                errorMessage={
                  !forwardedCall || validatePhoneNumber(forwardedCall)
                    ? ''
                    : 'Please provide a valid phone number.'
                }
              />
            </div>
          </div>
        )}

        <div className="d-flex flex-row flex-wrap mt-4">
          <div>
            <input
              type="radio"
              value={callSourceTypes.isCustomer}
              className="me-2"
              onChange={handleCallSourceSettingChange}
              checked={currentCallSource === callSourceTypes.isCustomer}
            />
            Calling party / end user / subscriber
          </div>
          <div className="ms-4">
            <label
              className={
                currentCallSource === callSourceTypes.isCustomer
                  ? 'form-label checkbox-label'
                  : 'form-label checkbox-label is-disable'
              }
            >
              I am both the call originator and the calling party
              <input
                type="checkbox"
                checked={callOriginatorAndCallingParty}
                onChange={() => {
                  setCallOriginatorAndCallingParty((v) => !v);
                  setCurrentCallSource(callSourceTypes.isCustomer);
                }}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>

        <div className="d-flex m-2 ms-4">
          NOTE: Do not use this section to provide information about your upstream voice service
          provider.
        </div>
        {currentCallSource === callSourceTypes.isCustomer && (
          <CallSourceCustomerForm
            currentCallSource={currentCallSource}
            callSource={callSource}
            customerErrors={validateCallSourceErrors(callSource)}
            setCallSource={setCallSource}
            campaignAttribute={campaignAttribute}
            disputeInfo={disputeInfo}
            setDisputeInfo={setDisputeInfo}
          />
        )}

        <div>
          <div className="d-flex mt-4">
            <input
              type="radio"
              value={callSourceTypes.isCallRecordNotProvided}
              className="me-2"
              onChange={handleCallSourceSettingChange}
              checked={currentCallSource === callSourceTypes.isCallRecordNotProvided}
            />
            Call record not provided
          </div>
        </div>

        <div
          className={
            'd-flex flex-row flex-wrap mt-2' +
            (currentCallSource && currentCallSource !== callSourceTypes.isCallRecordNotProvided
              ? 'is-disable'
              : '')
          }
        >
          <Label className="telecom-label ms-3">
            Select One
            <i className="fa fa-asterisk asterisk" />
          </Label>
          <div
            className={
              user.roleType === userTypes.Admin ? 'd-flex flex-column' : 'd-flex flex-row flex-wrap'
            }
          >
            <span className="ms-2">
              <input
                type="radio"
                name="callSourceTypes"
                value="isRecordUn"
                onChange={(e) => handleCauseChange(e)}
                checked={callSource.currentCause === 'isRecordUn'}
                className="ms-2 me-2"
              />
              Record unavailable
            </span>
            <span className="ms-2">
              <input
                type="radio"
                name="callSourceTypes"
                value="isCallOld"
                onChange={(e) => handleCauseChange(e)}
                checked={callSource.currentCause === 'isCallOld'}
                className="ms-2 me-2"
              />
              Call is too old
            </span>
            <span className="ms-2">
              <input
                type="radio"
                name="callSourceTypes"
                value="isRefusing"
                onChange={(e) => handleCauseChange(e)}
                checked={callSource.currentCause === 'isRefusing'}
                className="ms-2 me-2"
              />
              We refuse to share this information
            </span>
            {user.roleType === userTypes.Admin && (
              <>
                <span className="ms-2">
                  <input
                    type="radio"
                    name="callSourceTypes"
                    value="isProviderNonRes"
                    onChange={(e) => handleCauseChange(e)}
                    checked={callSource.currentCause === 'isProviderNonRes'}
                    className="ms-2 me-2"
                  />
                  Provider is non-responsive
                </span>
                <span className="ms-2">
                  <input
                    type="radio"
                    name="callSourceTypes"
                    value="tracebackCancel"
                    onChange={(e) => handleCauseChange(e)}
                    checked={callSource.currentCause === 'tracebackCancel'}
                    className="ms-2 me-2"
                  />
                  Cancel Traceback
                </span>
                <span className="ms-2">
                  <input
                    type="radio"
                    name="callDestinationTypes"
                    value="imposter"
                    onChange={(e) => handleCauseChange(e)}
                    checked={callSource.currentCause === 'imposter'}
                    className="ms-2 me-2"
                  />
                  Flag this hop Provider as Imposter
                </span>
              </>
            )}
          </div>
        </div>
        {currentCallSource === callSourceTypes.isCallRecordNotProvided &&
          !callSource.currentCause && (
            <InputError className="telecom-input-error ms-5">The field is required.</InputError>
          )}
        <div
          className={
            currentCallSource && currentCallSource !== callSourceTypes.isCallRecordNotProvided
              ? 'is-disable'
              : ''
          }
        >
          <div className="call-record-explanation">
            <label className="telecom-label">
              Explanation
              <i className="fa fa-asterisk asterisk" />
            </label>
            <InputFormGroup
              isReadonly={false}
              isTextarea
              inputName="explanation"
              inputId="explanation"
              inputClassName="input-hop"
              inputValue={callSource.explanation}
              inputOnChange={handleCallSourceInputChange}
              inputPlaceholder="Please leave a response explaining why you are either unable to locate the call record or are unwilling to share this information.
                                            If no match found using Calling Number, please try searching for the Called Number only."
              inputAutoComplete="off"
            />
            {currentCallSource === callSourceTypes.isCallRecordNotProvided &&
              !callSource.explanation && (
                <InputError className="telecom-input-error">The field is required.</InputError>
              )}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            style={{ minHeight: '30px' }}
            onClick={() => {
              submitConfirmModalToggle();
            }}
            disabled={!canSubmit()}
          >
            Submit Traceback Response
          </Button>
        </div>
      </Form>
      <Modal
        centered
        isOpen={isSubmit}
        className="submit-confirm-modal"
        toggle={submitCallSourceToggle}
      >
        <ModalHeader toggle={submitCallSourceToggle} />
        <ModalBody>
          <h5 className="traceback-sub-title">
            Once you update this hop, its status will no longer be editable. However, you may still
            submit comments.
          </h5>
          <h5 className="traceback-sub-title">Submit this form?</h5>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={submitCallSourceToggle}
          >
            Cancel
          </Button>
          <Button
            className="telecom-btn"
            style={{ background: '#d61827', textDecoration: 'none', color: 'white' }}
            onClick={() => {
              submitCallSource();
            }}
          >
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        toggle={providerAlreadtyInSequenceToggle}
        isOpen={providerAlreadyInSequenceModal}
        className="already-existing-modal"
      >
        <ModalHeader toggle={providerAlreadtyInSequenceToggle}></ModalHeader>
        <ModalBody>
          <h5>This provider has already participated on an earlier hop in this traceback.</h5>
          <p className="text-center text-md-left mt-4 mb-n3">
            Are you sure you want to continue ?<br />
            Please send an email to{' '}
            <a href="mailto:support@tracebacks.org">support@tracebacks.org</a> if you believe there
            is an error.
          </p>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={providerAlreadtyInSequenceToggle}
          >
            Cancel
          </Button>
          <Button
            className="telecom-btn"
            onClick={() => submitAlreadyExistingPreviousProviderHop()}
          >
            Submit Anyway
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        centered
        className="already-existing-modal"
        toggle={addressModalToggle}
        isOpen={addressModal}
      >
        <ModalHeader toggle={addressModalToggle}>
          <label className="text-center">Complete Address not provided !</label>
        </ModalHeader>
        <ModalBody>
          <div style={{ marginLeft: '20px' }}>
            <label className="label-bold">List of missing fields :</label>
            <ul style={{ padding: 20 }}>
              {callSource.addressLine1 === '' ? <li>Address</li> : ''}
              {callSource.city === '' ? <li>City</li> : ''}
              {callSource.region === '' ? <li>Region</li> : ''}
              {callSource.postalCode === '' ? <li>Zip/Postal Code</li> : ''}
              {callSource.ipAddress === '0.0.0.0' ? (
                <li className="text-red">Calling Party IP Addresss: 0.0.0.0</li>
              ) : (
                ''
              )}
            </ul>
          </div>

          <div style={{ marginLeft: '20px', marginTop: '30px' }}>
            <div>
              <label className="label-bold">Choose one*</label>
            </div>

            <input
              type="radio"
              name="reason"
              onChange={() => handleReasonReject('do_not_collect')}
            />
            <span className="p-2">Do not collect</span>
            <div>
              <input
                type="radio"
                name="reason"
                onChange={() => handleReasonReject('unwilling_to_share')}
              />
              <span className="p-2">Unwilling to share</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button className="btn-default telecom-btn" color="light" onClick={addressModalToggle}>
            Cancel
          </Button>
          <Button
            disabled={callSource.reasonRejection === ''}
            className="telecom-btn"
            onClick={() => continueToModal()}
          >
            Continue
          </Button>
        </ModalFooter>
      </Modal>
      <StirShakenModal
        stirShakenInfo={stirShakenInfo}
        setStirShakenInfo={setStirShakenInfo}
        submitConfirmModalToggle={submitCallSourceToggle}
      />
      <Modal
        centered
        className="modal-template mt-5 mb-5"
        size="lg"
        isOpen={findRecordModal}
        toggle={toggleFindRecordModal}
      >
        <ModalHeader toggle={toggleFindRecordModal}>Help Finding Record</ModalHeader>
        <ModalBody>
          <p>
            It's critical to find the source of the traceback. If you are unable to locate the call
            based on the details here:
          </p>
          <p>TIME: {getClientFormattedDate(traceback.tracebackTime, DateFormat.LongBoth)}</p>
          <p>CALLING: {traceback.callingTN}</p>
          <p>CALLED: {traceback.calledTN}</p>
          <p>
            Please use the comment area here to message connecting partner to request information
            such as p-cap, IP address or anything else that will help locate the call record.
          </p>
          <h5 className="p-0">
            {`${hop.hopProvider.name} → ${hop.downstreamProvider?.name} & ITG Administrators`}:
          </h5>
          <AddHopComment
            isCallSourcePopulated={true}
            hopID={hop.hopId}
            hopProviderName={hop.hopProvider.name}
            upstreamProviderName={hop.upstreamProvider ? hop.upstreamProvider.name : ''}
            downstreamProviderName={hop.downstreamProvider ? hop.downstreamProvider.name : ''}
            forceDirectedTo="downstream"
            uniqueId="button_modal"
          />
        </ModalBody>
      </Modal>

      <Modal
        centered
        className="modal-template mt-5 mb-5"
        size="lg"
        isOpen={customerEmailModal}
        toggle={toggleCustomerEmailModal}
      >
        <ModalHeader toggle={toggleCustomerEmailModal}>Customer matches provider</ModalHeader>
        <ModalBody>
          <p>
            It appears that the enduser/caller information matches with an existing Voice Service
            Provider,{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSelectedProvider(customerEmailProvider?.id);
                setCurrentCallSource(callSourceTypes.isUpstream);
                toggleCustomerEmailModal();
              }}
            >
              {customerEmailProvider?.name}
            </a>
            . If this is correct please select them as your upstream. Otherwise press 'Submit As
            Enduser' to continue.
          </p>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={() => {
              toggleCustomerEmailModal();
              submitConfirmModalToggle(true);
            }}
          >
            Submit As Enduser
          </Button>
          <Button
            className="telecom-btn"
            onClick={() => {
              setSelectedProvider(customerEmailProvider?.id);
              setCurrentCallSource(callSourceTypes.isUpstream);
              toggleCustomerEmailModal();
            }}
          >
            Select as Upstream Provider
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        className="modal-template mt-5 mb-5"
        size="lg"
        isOpen={immediateDownstreamModal}
        toggle={toggleImmediateDownstreamModal}
      >
        <ModalHeader toggle={toggleImmediateDownstreamModal}>
          Warning - Upstream Provider Selected.
        </ModalHeader>
        <ModalBody>
          <p>{`The upstream provider you selected is the same as the downstream provider, ${hop.downstreamProvider?.name}.`}</p>
          <p>{`Please use the comment area here to message the downstream provider ${hop.downstreamProvider?.name} to ensure this is correct.`}</p>
          <AddHopComment
            isCallSourcePopulated={true}
            hopID={hop.hopId}
            hopProviderName={hop.hopProvider.name}
            upstreamProviderName={hop.upstreamProvider ? hop.upstreamProvider.name : ''}
            downstreamProviderName={hop.downstreamProvider ? hop.downstreamProvider.name : ''}
            forceDirectedTo="downstream"
            uniqueId="downstream_modal"
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    providersData: sm.provider.providerNames || [],
    isNrProvider: sm.provider.isNrProvider
  };
};

const mapActionsToProps = { getProviderIsNonResponsive };
export default connect(mapStateToProps, mapActionsToProps)(CallSourceRow);
